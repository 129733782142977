// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "@app/app.component";
import { appConfig } from "@app/app.config";
import { environment } from "./environments/environment";
import { enableProdMode } from "@angular/core";

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(
    AppComponent,
    appConfig
)
.catch(err => console.error("[Application error]:", err));