// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { Component, inject } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { Actions, ofActionDispatched, Store } from "@ngxs/store";
import { TlService } from "@services/tl/tl.service";
import { cat } from "@assets/proto/msgs";
import { Observable } from "rxjs";
import { appRouteNames } from "@app/app.routes.names";
import { AppRoutingModule } from "@app/app-routing.module";
import { LoggerService } from "@services/logger/logger.service";
import { CommonService } from "@services/common/common.service";
import { Title } from "@angular/platform-browser";
import { title } from "../version";
import { AuthService } from "@services/auth/auth.service";
import { protosui } from "src/definitions/definitions";
import { setConnection } from "@store/actions";
import { IState } from "@shared/app-models";
import { Route } from "@angular/router";
import { SubscriptionManager } from "@shared/subscription-manager";
import { SharedModule } from "@shared/shared.module";
import { GuidedTourModule } from "ngx-guided-tour";
import { TranslateModule } from "@ngx-translate/core";
import { MatSidenavModule } from "@angular/material/sidenav";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    standalone: true,
    imports: [GuidedTourModule, TranslateModule, SharedModule, RouterModule, MatSidenavModule]
})

export class AppComponent extends SubscriptionManager {

    me$: Observable<cat.UserMsg> = inject(Store).select((state: IState) => state.cat.Me.msg);

    public appPages: Route[] = [];
    public isMenuOpen = true;
    public uitext = protosui.messages.uitext;
    public helptext = protosui.messages.helptour;

    constructor(
        public auth: AuthService,
        private _common: CommonService,
        private _logger: LoggerService,
        private _tls: TlService,
        private _actions: Actions,
        private _titleService: Title,
        private _appRoutes: AppRoutingModule,
        private _router: Router,
        private _store: Store) {

        super();
        _tls.setDefault();
        this.initializeApp();
        this.appPages = _appRoutes.getRoutes(true);
        this._titleService.setTitle(title);

        // Retrieve menu state from local storage (default=open).
        this.isMenuOpen = JSON.parse(localStorage.getItem("isMenuOpen")) === null
            ? true
            : JSON.parse(localStorage.getItem("isMenuOpen"));

        this.addSubscriptions([
            this.auth.isAuth.subscribe(v => {
                if (!v) {
                    this._appRoutes.clearRoutes();
                }
            }),
            this._appRoutes.routeUpdate.subscribe(() => this.appPages = _appRoutes.getRoutes(false)),
            this._actions
                .pipe(ofActionDispatched(setConnection))
                .subscribe((action: setConnection) => {
                    this._logger.debug(`Websocket connection state: ${action.isConnected ? "connected" : "not connected" }`);
                    // Redirect to disconnected if there is no Websocket connection
                    if (!action.isConnected) {
                        this._router.navigate([appRouteNames.DISCONNECTED], { replaceUrl: true });
                    }
                })
            ]);
    }

    /**
     * Open or close the side menu.
     *
     * @memberof AppComponent
     */
    public toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
        localStorage.setItem("isMenuOpen", JSON.stringify(this.isMenuOpen));
    }

    /**
     * Close all overlays when navigating to (another) page.
     *
     * @memberof AppComponent
     */
    public async navigate() {
        this.auth.clearBackPath();
    }

    /**
     * Initialize the app, set language, set default page etc.
     *
     * @memberof AppComponent
     */
    async initializeApp() {
        try {

            this._logger.debug("INITIALIZE APP...");

            // Navigate to disconnected by default.
            this._router.navigate([appRouteNames.DISCONNECTED], { replaceUrl: true, skipLocationChange: true });

            // Add supported and set the language.
            this._tls.addSupportedLanguages();
            this._tls.useLanguage(cat.SupportedLanguages.LANGUAGE_ENGLISH);

            // Remove modals when pushing browser back button.
            window.onpopstate = async () => await this._common.dismissAllOverlays();

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Navigate to the user profile page
     *
     * @memberof AppComponent
     */
    async goUserDetails() {
        const user: cat.UserMsg = this._store.selectSnapshot((state: IState) => state.cat.Me.msg);
        await this._router.navigate([`${appRouteNames.USER_SETTINGS}/${user.id}`]);
        this.auth.clearBackPath();
    }
}
