// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, provideExperimentalZonelessChangeDetection } from "@angular/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

import { registerLocaleData } from "@angular/common";
import { TlService, TranslatePoHttpLoader } from "@services/tl/tl.service";
import { BrowserModule } from "@angular/platform-browser";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { provideRouter } from "@angular/router";
import { APP_ROUTES, AppRoutingModule } from "./app-routing.module";
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from "@angular-material-components/datetime-picker";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from "@angular/material/tooltip";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PipesModule } from "@pipes/pipes.module";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { GuidedTourModule, GuidedTourService } from "ngx-guided-tour";
import { WsService } from "@services/ws/ws.service";
import { QueryService } from "@services/query/query.service";
import { provideLottieOptions } from "ngx-lottie";
import { NgxsModule } from "@ngxs/store";
import { AppState } from "@store/store";
import { environment } from "src/environments/environment";

import localDE from "@angular/common/locales/de";
import localeNL from "@angular/common/locales/nl";
import localeFR from "@angular/common/locales/fr";
import localeES from "@angular/common/locales/es";

registerLocaleData(localDE);
registerLocaleData(localeNL);
registerLocaleData(localeFR);
registerLocaleData(localeES);

/**
 * Create the translate loader
 * @param {HttpClient} http The http client
 * @returns {TranslatePoHttpLoader} A translate po http loader
 */
export function createTranslateLoader(http: HttpClient) {
    return new TranslatePoHttpLoader(http, "assets/i18n", ".po");
}

export const appConfig : ApplicationConfig = {
    providers : [
        provideRouter(APP_ROUTES),
        AppRoutingModule,
        PipesModule,
        WsService,
        {
            provide: APP_INITIALIZER,
            useFactory: (wsService: WsService) => () => wsService.init(),
            deps: [WsService],
            multi: true
        },

        QueryService,
        GuidedTourService,
        TlService,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                enterAnimationDuration: 1
            } // Default: snappy
        },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: {
                showDelay: 1000
            } // Default 1 sec delay
        },
        importProvidersFrom(
            // Angular modules
            BrowserModule,
            NgxChartsModule,
            GuidedTourModule,
            MatDialogModule,
            MatButtonModule,
            MatSidenavModule,
            MatListModule,
            MatIconModule,
            NgxMatDatetimePickerModule,
            NgxMatTimepickerModule,
            NgxMatNativeDateModule,
            MatTooltipModule,
            MatSnackBarModule,
            ReactiveFormsModule,
            BrowserAnimationsModule,
            // External modules
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: (createTranslateLoader),
                    deps: [HttpClient]
                }
            }),
            NgxsModule.forRoot([AppState], {
                developmentMode: !environment?.production
            }),
            NgxsReduxDevtoolsPluginModule.forRoot({
                name: "CAT",
                disabled: true,
                maxAge: 10,
            }),
            NgxsLoggerPluginModule.forRoot({
                disabled: true
            })
        ),
        provideHttpClient(withInterceptorsFromDi()),
        provideExperimentalZonelessChangeDetection(),
        provideLottieOptions({
            player: () => import("lottie-web")
        })
    ]
}