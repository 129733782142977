<ngx-guided-tour [doneText]="helptext.done | translate" [backText]="helptext.back | translate" [skipText]="helptext.skip | translate" [nextText]="helptext.next | translate"></ngx-guided-tour>

<mat-drawer-container autosize [class.cat-expanded]=isMenuOpen class="cat-main-container">
    <mat-drawer *ngIf="(auth.isLoggedIn() | async)" #sidenav mode="side" opened="isMenuOpen" [class.cat-expanded]=isMenuOpen>
        <mat-nav-list class="cat-no-padding">

            <header class="cat-full-width">
                <mat-list-item (click)="toggleMenu()" class="cat-menu" id="help-menu-button">
                    <mat-icon matListItemIcon class="chevron-button cat-no-padding cat-margin-bottom-xs" [class.cat-rotated]=isMenuOpen color="dark">
                        chevron_right
                    </mat-icon>
                    <ng-container *ngIf="(me$ | async) as me">
                        <button
                            *ngIf="isMenuOpen"
                            (click)="goUserDetails(); $event.stopPropagation();"
                            mat-button class="cat-float-end">
                            <mat-icon class="cat-no-margin">settings</mat-icon>
                            {{ uitext.usersettings | translate }}
                        </button>
                    </ng-container>
                </mat-list-item>
            </header>

            <main class="cat-full-width">
                <ng-container *ngFor="let page of appPages let last = last">
                    <mat-list-item
                        *ngIf="!last"
                        [id]="page.path"
                        [routerLink]="'/' + page.path"
                        (click)="navigate()"
                        routerDirection="root"
                        matTooltip="{{ !isMenuOpen ? (page.data?.title | translate | replaceTerm) : null }}"
                        matTooltipPosition="right"
                        routerLinkActive="cat-active-sidemenu-item">
                        <mat-icon matListItemIcon color="primary">
                            {{ page.data?.icon?.matname }}
                        </mat-icon>
                        <div *ngIf="isMenuOpen" matListItemTitle>
                            {{ ((page.data?.title | translate | replaceTerm) || (page.path | translate | replaceTerm)) }}
                        </div>
                    </mat-list-item>
                </ng-container>
            </main>

            <footer class="cat-full-width">
                <ng-container *ngIf="appPages[appPages.length -1] as page">
                    <mat-list-item
                        matTooltip="{{ !isMenuOpen ? (page.data?.title | translate | replaceTerm) : null }}"
                        matTooltipPosition="right"
                        [id]="page.path"
                        [routerLink]="'/' + page.path"
                        (click)="navigate()"
                        routerDirection="root"
                        routerLinkActive="cat-active-sidemenu-item">
                        <mat-icon matListItemIcon color="danger">
                            {{ page.data.icon.matname }}
                        </mat-icon>
                        <div *ngIf="isMenuOpen" matListItemTitle color="danger">
                            <b>{{ ((page.data.title | translate | replaceTerm) || (page.path | translate | replaceTerm)) }}</b>
                        </div>
                    </mat-list-item>
                </ng-container>
            </footer>

        </mat-nav-list>

    </mat-drawer>

    <div
        class="cat-main-content"
        [ngClass]="{
            'cat-logged-in' : (auth.isLoggedIn() | async ),
            'menu-open' : isMenuOpen }">
        <router-outlet></router-outlet>
    </div>


</mat-drawer-container>
